.quienessomos-titulo {
  color: rgb(23, 148, 199);
  padding-top: 50px;
  margin-bottom: 40px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.quienessomos-content {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 20px;
  padding-left: 0px;
  margin-bottom: 50px;
}
.social-links{
  margin-top: 40px;
  list-style: none;
  display: flex;
  padding-left: 0px;
  a{
    display: inline-block;
    margin-right: 10px;
    padding: 10px;
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
    min-height: 40px;
    background-color:#1894c7;
    color: white;
    border-radius: 50%;
    i{
      width: 20px;
      margin: auto;
      text-align: center;
      display: block;
    }
  }
}
@media (max-width: 1099px) {
  .quienessomos-titulo {
    width: 80%;
  }
  .quienessomos-content {
    width: 80%;
  }
}

.paso-extra {
  position: relative;
  padding: 10px;
}