.donaciones{
    .dashboard-content-inner{
        height: 100%;
    }
    .job-listing-title{
        margin-right: 10px;
    }
    .job-listing-details{
        padding-left: 0px;
    }
}