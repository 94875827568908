.mis-necesidades {
  height: 100%;
  .controles-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 400px) {
      flex-direction: column;
    }
    .buscar-necesidad-input {
      margin-left: 50px;
      @media (max-width: 992px) {
        margin-left: 15px;
      }
      margin-right: 15px;
      width: 150px;
      margin-top: 20px;
    }
  }
  .filtros-necesidades{
    margin-left: 50px;
    display: flex;
    flex-wrap: wrap;
    .checkbox{
      margin-right: 10px;
    }
  }
  .mis-necesidades-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dashboard-content-inner {
    height: 100%;
    @media (min-width: 993px) {
        padding-top: 15px;
    }
  }
  .job-listing-title {
    margin-right: 10px;
  }
  .job-listing-details {
    padding-left: 0px;
  }
}
