.detalle-necesidad {
  .boton-paypal {
    display: inline-block;
    background-color: orange;
  }
  .apoya-button {
    width: 190px;
  }
  .donaciones-y-progreso-wrapper {
    display: flex;
    margin: auto;
    margin-bottom: 20px;
    font-size: 20px;
    background: radial-gradient(
      circle,
      rgb(232, 232, 232) 40%,
      rgba(255, 255, 255, 1) 100%
    );
    h3 {
      font-size: 35px;
      line-height: 35px;
      margin-bottom: 40px;
    }
    .donaciones-block {
      padding: 20px;
      min-width: 420px;
      text-align: center;
      .donaciones-border-block {
        h3 {
          padding-right: 40px;
          margin-bottom: 25px;
        }
        .donaciones-subtitulo {
          width: 90%;
          margin: auto;
          color: #333;
          padding-right: 40px;
        }
        .boton-donacion,
        .boton-donacion-2 {
          margin-top: 17px;
          margin-bottom: 10px;
          background-color: #73cade;
          width: 70px;
          height: 70px;
          line-height: 70px;
          padding: 0;
          border-radius: 35px;
          font-size: 20px;
          margin-right: 20px;
        }
        .boton-donacion:hover,
        .boton-donacion-2:hover {
          transform: scale(1.1);
        }
        .boton-donacion-2 {
          background-color: #6dadd2;
        }
        .donar-ahora-wrapper {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .error-monto-mensaje {
            color: #ff764c;
            font-size: 16px;
          }
          .input-monto-donacion {
            height: 30px;
            line-height: 24px;
            width: 150px;
            &.error-monto {
              border: 1px solid #ff764c;
            }
          }
          .boton-donar-ahora {
            background-color: #6279a9;
            border-radius: 4px;
            height: 30px;
            padding: 0;
            padding-left: 5px;
            padding-right: 5px;
            font-weight: 400;
            text-align: center;
            line-height: 30px;
            font-size: 18px;
            color: #fff;
            margin-left: 10px;
            transition: 0.3s;
          }
        }
      }
    }
    .progreso-block {
      min-width: 420px;
      padding-left: 60px;
      text-align: center;
      h3 {
        margin-top: 20px;
        margin-bottom: 25px;
      }
      .barra-progreso {
        height: 10px;
        width: 100%;
        background-color: #d6d6d6;
        border-radius: 10px;
        position: relative;
        margin: 10px 0;
        span {
          width: 0%;
          height: 10px;
          background-color: #2a41e8;
          position: absolute;
          top: 0;
          left: 0;
          margin: 0;
          border-radius: 10px;
        }
      }
      .mensaje-progreso {
        color: #333;
        width: 90%;
      }
      img {
        width: 70px;
      }
    }
  }
  @media (max-width: 992px) {
    .donaciones-y-progreso-wrapper {
      flex-direction: column;
      .donaciones-block {
        order: 1;
        .donaciones-border-block {
          h3 {
            padding-right: 0;
          }
          .donaciones-subtitulo{
            padding-right: 0;
          }
        }
      }
      .progreso-block {
        order: 0;
        padding-left: 0;
        .barra-progreso {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  @media (max-width: 420px) {
    .donaciones-y-progreso-wrapper {
      .donaciones-block {
        min-width: 100%;
        .donaciones-border-block{
          .boton-donacion, .boton-donacion-2{
            width: 48px;
            height: 48px;
            line-height: 48px;
            border-radius: 24px;
            font-size: 15px;
            margin-right: 10px;
            margin-top: 20px;
          }
          .donar-ahora-wrapper{
            .input-monto-donacion{
              width: 150px;
            }
          }
        }
      }
      .progreso-block {
        min-width: 100%;
      }
    }
  }
  @media (max-width: 680px) {
    .descripcion-detalle {
      width: 100%;
      min-width: 100%;
    }
    .botones-detalle {
      width: 100%;
      min-width: 100%;
    }
  }
  .boton-carrito {
    background-color: #f57c00;
    line-height: 24px;
    color: #fff;
    display: block;
    width: 50px;
    height: 40px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
  }
  .single-page-header {
    padding: 0px;
    padding-top: 15px;
    padding-bottom: 20px;
    .header-image {
      height: 90px;
      background: none;
      box-shadow: none;
      padding: 0px;
    }
    .detalle-titulo-bold {
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }
    .porcentaje-necesidad {
      max-width: 100px;
    }
    @media (max-width: 992px) {
      .porcentaje-necesidad {
        max-width: 90px;
      }
    }
  }
  .cic-yt-iframe {
    margin: auto;
    margin-bottom: 30px;
    width: 100%;
    max-width: 711px;
    height: 400px;
  }
  .sidebar-container {
    .copy-url {
      min-width: 190px;
    }
  }
  @media (max-width: 677px) {
    .row {
      margin-right: 0px;
    }
    .single-page-header {
      margin-bottom: 30px;
    }
    .single-page-section {
      margin-bottom: 20px;
    }
    .cic-yt-iframe {
      margin: auto;
      margin-bottom: 30px;
      width: 100%;
      max-width: 711px;
      height: 315px;
    }
    /*     .share-buttons-icons {
      flex-direction: column;
      a {
        border-radius: 0px;
      }
      li:first-child a {
        border-radius: 4px 4px 0px 0px;
      }
      li:last-child a {
        border-radius: 0px 0px 4px 4px;
      }
    } */
  }
  .job-overview {
    background-color: #fbfbfb;
    .job-overview-headline {
      padding: 0px;
      background-color: #fbfbfb;
    }
    .job-overview-inner {
      padding-left: 0px;
      padding-bottom: 0px;
    }
  }
}

.enviar-datos-contacto {
  .sin-errores {
    display: none;
  }
}

.datos-enviados-loading {
  position: absolute;
  top: 30%;
  left: 30%;
  z-index: 999;
}

@media (max-width: 575px) {
  .datos-enviados-loading {
    top: 30%;
    left: 15%;
  }
}
