.comofunciona-wrapper{
    display: flex;
    .comofunciona-texto{
        padding-left: 10%;
        padding-top: 60px;
        width: 50%;
        .comofunciona-titulo{
            margin-bottom: 20px;
            color: rgb(23, 148, 199);
        }
        .comofunciona-subtitulo{
            margin-bottom: 30px;
            font-style: italic;
            font-size: 20px;
        }
    }
}
@media (max-width: 1099px) {
    .comofunciona-wrapper{
        flex-direction: column;
        align-items: center;
        .comofunciona-texto{
            padding-top: 40px;
            width: 100%;
        }
    }
}