.beneficios-titulo{
  color: rgb(23, 148, 199);
  padding-top: 50px;
  margin-bottom: 40px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.beneficios-content{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 20px;
  margin-bottom: 50px;
}
@media (max-width: 1099px) {
  .beneficios-titulo{
    width: 80%;
  }
  .beneficios-content{
    width: 80%;
  }
}
.paso-extra{
  position: relative;
  padding: 10px;
}
.beneficios-content::after, .paso-extra::after {
  display: block;
  content: "";
  -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0,0,0,0.23);
  -moz-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0,0,0,0.23);
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0,0,0,0.23);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.paso-como-funciona{
  background-image: url("https://apoyacic.s3.amazonaws.com/imagenes/barra_fondo_0.png");
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 105px;
  position: relative;
  margin-bottom: 15px;
  img{
    padding: 5px;
    max-width: 15%;
    padding-right: 10px;
  }
  @media (max-width: 575px) {
    img {
      max-width: 25%;
    }
  }
  .paso-placeholder-img{
    width: 107px;
    min-width: 107px;
  }
}

.paso-como-funciona::after{
  display: block;
  content: "";
  -webkit-box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0,0,0,0.23);
  -moz-box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0,0,0,0.23);
  box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0,0,0,0.23);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.paso-extra{
  background-color: rgb(225, 225, 225);
}