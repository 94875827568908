.dataTables_length{
    label{
        display: inline;
    }
    select{
        height: 30px;
        width: 53px;
        display: inline-block;
        margin-bottom: 0px;
        padding: 0px;
    }
}
.dataTables_filter{
    label{
        display: inline;
    }
    input{
        display: inline-block;
        width: 208px;
    }
}
#todos-contactos-table{
    th{
        white-space: nowrap;
    }
}
.todos-contactos-clear{
    clear: both;
}