#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 360px;
  .footer-bottom-section {
    padding: 0px;
    padding-top: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    .newsletter-block{
        width: 16%;
        #mc_embed_signup_scroll {
            display: flex;
          .cic-email-subscribe {
            background: #262626;
            box-shadow: none;
            margin-bottom: 0;
            border: none;
            padding: 0 20px;
            padding-right: 0;
            margin-right: 0;
            width: 100%;
          }
          .cic-newsletter-subscribe-btn{
              background-color: #2a41e8;
              border-radius: 4px;
              height: 48px;
              min-width: 48px;
              max-width: 48px;
              text-align: center;
              line-height: 48px;
              font-size: 18px;
              color: #fff;
              margin-left: 10px;
              transition: 0.3s;
          }
        }
    }
    .footer-links {
      width: 16%;
      .contacto-item {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin-right: 5px;
        }
      }
    }
    .contacto-block {
      width: 16%;
      .contacto-item {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin-right: 5px;
        }
      }
    }
    .iniciativa-block {
      width: 16%;
      h3 {
        margin: 0;
      }
      img {
        margin-top: 15px;
      }
    }
    .aviso-block {
      width: 16%;
      a {
        color: white;
      }
    }
  }
}

@media (max-width: 1099px) {
  #footer {
    height: 400px;
    .footer-bottom-section {
      h3 {
        margin-top: 20px;
      }
      .contacto-block {
        width: 20%;
      }
      .apoya-block {
        width: 20%;
        .iniciativa-de {
          flex-wrap: wrap;
          img {
            margin-top: 20px;
          }
        }
      }
      .desarrollado-block {
        width: 20%;
      }
      .iniciativa-block {
        width: 20%;
      }
      .aviso-block {
        width: 20%;
      }
    }
  }
}

@media (max-width: 867px) {
  #footer {
    height: 1000px;
    .footer-bottom-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      .footer-links {
        width: 70%;
      }
      .newsletter-block{
        width: 80%;
        #mc_embed_signup_scroll {
            justify-content: center;
        }
      }
      .contacto-block {
        width: 80%;
        h3 {
          margin-top: 0px;
        }
      }
      .apoya-block {
        width: 80%;
        max-width: 296px;
      }
      .desarrollado-block {
        width: 80%;
        max-width: 200px;
        h3 {
          margin-top: 20px;
          margin-bottom: 0px;
        }
      }
      .iniciativa-block {
        width: 80%;
        h3 {
          margin-top: 20px;
          margin-bottom: 10px;
        }
      }
      .aviso-block {
        h3 {
          margin-top: 40px;
        }
        width: 80%;
      }
    }
  }
}

@media (max-width: 492px) {
    #footer {
      height: 1200px;
    }
}
