.carrusel-necesidades {
  padding-left: 10%;
  padding-right: 2%;
  .necesidades-titulo {
    display: flex;
    justify-content: space-between;
    h3 {
      font-size: 35px;
      color: #303030;
      font-weight: 600;
    }
  }
  .slick-slide {
    height: 230px;
    .freelancer-name h4 {
      cursor: default;
    }
  }
  .slick-list{
    .slick-track{
      display: flex;
      align-items: stretch;
      .job-listing {
        height: auto;
        margin: 15px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .job-listing-details {
          display: block;
          .descripcion-necesidad{
            text-align: center;
            .nombre-categoria {
              color: gray;
              text-align: center;
              font-weight: 600;
            }
          }
    
          .requisitos-label {
            color: gray;
            font-weight: 600;
          }
          .requisitos-necesidad {
            color: #333;
          }
    
          .costo-estimado-label{
            color: gray;
            font-weight: 600;
          }
          .costo-estimado {
            color: #333;
          }
          .imagen-necesidad {
            display: flex;
            min-width: 90px;
            justify-content: center;
            img{
              max-width: 146px;
              max-height: 146px;
              border-radius: 8px;
            }
          }
          .apoya-yt{
            border-radius: 8px;
          }
          @media (max-width: 450px) {
            .imagen-necesidad {
              flex-direction: column;
              align-items: center;
            }
          }
        }
        .job-listing-footer {
          padding: 0px;
          padding-bottom: 5px;
          width: 100%;
          display: flex;
          flex-direction: column;
          .footer-container, .footer-container-2 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            overflow: hidden;
            .footer-item {
              margin-left: 10px;
              margin-top: 8px;
              margin-right: 10px;
              color: #333;
              display: flex;
              flex-direction: column;
              align-items: center;
              div:first-child{
                color: gray;
              }
              ul {
                li {
                  margin-right: 0px;
                }
              }
              a.button{
                padding: 7px 15px;
              }
            }
          }
        }
      }
    }
  }
  .necesidades-inscribete {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    i {
      margin-left: 3px;
      font-size: 20px;
      transform: scaleX(1.5);
    }
  }
}
