.checkout-datos {
  overflow-x: hidden;
  .sin-errores {
    display: none;
  }
  .checkbox {
    margin-right: 20px;
  }
  .boton-enviar {
    margin-top: 20px;
  }
  .popup-tabs-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 580px;
    #id-btn-pp{
      height: 600px !important;
    }
    .popup-tab-content {
      padding-bottom: 20px;
      @media (max-width: 690px) {
        padding-bottom: 20px;
      }
      @media (max-width: 412px) {
        padding-right: 0px;
      }
      .welcome-text {
        margin-bottom: 10px;
      }
      #paypal-btn-container {
        max-width: 255px;
        margin: auto;
        margin-top: 20px;
      }
      .resumen-necesidades-tabla {
        width: 100%;
        @media (max-width: 690px) {
          display: none;
        }
        margin-top: 40px;
        thead {
          background-color: #f0f0f0;
          color: #333;
          th {
            padding: 15px;
          }
        }
        tbody {
          td {
            padding: 10px;
          }
        }
        .category-cell {
          padding-left: 20px;
          padding-right: 20px;
          color: #333;
        }
        .ticket-cell {
          padding-left: 20px;
        }
        .costo-cell {
          color: #333;
        }
        .quitar-btn-wrapper {
          .quitar-btn {
            font-size: 20px;
            position: relative;
            font-weight: 600;
            display: inline-block;
            color: #808080;
          }
        }
        .quitar-btn:hover {
          cursor: pointer;
        }
        tr:hover {
          .quitar-btn {
            display: inline-block;
            color: red;
          }
        }
        .total-row {
          background-color: #f0f0f0;
          color: #333;
          font-weight: bold;
          td {
            padding: 15px;
          }
          .total-cell {
            white-space: nowrap;
            color: black;
          }
        }
      }
      .resumen-necesidades-mobile {
        display: none;
        @media (max-width: 690px) {
          display: block;
          margin-top: 20px;
          .datos-necesidad {
            position: relative;
            background-color: #c0ffc0;
            color: #333;
            border-radius: 8px;
            padding: 5px;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 10px;
            margin-bottom: 20px;
            text-align: left;
            padding-left: 20px;
            .categoria-row {
              width: calc(100% - 30px);
            }
            .quitar-btn {
              position: absolute;
              right: 10px;
              top: calc(50% - 10px);
              font-size: 20px;
              color: #808080;
            }
            .quitar-btn:hover {
              cursor: pointer;
            }
            .mensaje-mobile {
              margin-top: 10px;
              margin-right: 40px;
              input{
                margin-bottom: 0;
                height: 40px;
              }
            }
          }
          .datos-total {
            color: #333;
            background-color: #c0ffc0;
          }
          .datos-necesidad:hover {
            .quitar-btn {
              display: inline-block;
              color: red;
            }
          }
        }
      }
      .titulo-formulario{
        margin-top: 40px;
        margin-bottom: 10px;
        font-weight: 700;
        text-align: center;
      }
    }
  }
  .seccion-newsletter {
    width: 700px;
    margin: auto;
    display: block;
    margin-bottom: 10px;
    @media (max-width: 690px) {
      width: 100%;
      margin-left: 30px;
      padding-right: 30px;
      margin-bottom: 0px;
    }
  }
  .seccion-botones {
    display: flex;
    width: 700px;
    margin: auto;
    margin-bottom: 20px;
    .seguir-buscando-wrapper {
      width: 50%;
      margin-right: 30px;
      button {
        width: 220px;
        max-width: 220px;
        box-shadow: none;
        color: black;
        background-color: #f0f0f0;
        font-weight: 600;
        i {
          margin-right: 20px;
          transform: scaleX(1.1);
        }
      }
    }
    .pp-btn-seccion-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      h3{
        width: 90%;
      }
      .boton-enviar {
        width: 90%;
        i {
          margin-right: 10px;
        }
      }
      #continueButton.boton-enviar{
        width: 100%;
        margin-top: 0;
      }
    }
    @media (max-width: 690px) {
      flex-direction: column;
      align-items: flex-start;
      width: auto;
      .seguir-buscando-wrapper {
        display: block;
        width: 100%;
        margin-left: 30px;
        margin-bottom: 20px;
        order: 1;
        .boton-enviar {
          margin-top: 10px;
        }
      }
      .pp-btn-seccion-wrapper {
        margin-left: 30px;
        order: 0;
        .boton-enviar {
          margin-bottom: 20px;
          width: 100%;
          padding-left: 10px;
          padding-right: 10px;
          width: 95%;
        }
        h3 {
          margin-bottom: 15px;
          width: 100%;
        }
      }
    }
  }
}
