.testimonios-admin-wrapper {
  .titulo-tabla-testimonios {
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 20px;
  }
  .boton-tabla-testimonios {
    background-color: grey;
    color: white;
    padding: 3px;
    border-radius: 4px;
    margin-left: 5px;
  }
  .esconder-testimonio-btn{
    box-shadow: none;
    height: 22px;
    line-height: 22px;
    margin: 0;
    width: 22px;
    cursor: pointer;
  }
  .estatus-testimonio-select{
    width: 150px;
    &.faltan-datos{
      background-color: #fff7b2;
    }
  }
  .dataTables_length {
    label {
      display: inline;
    }
    select {
      height: 30px;
      width: 53px;
      display: inline-block;
      margin-bottom: 0px;
      padding: 0px;
    }
  }
  .dataTables_filter {
    label {
      display: inline;
    }
    input {
      display: inline-block;
      width: 208px;
    }
  }
  #testimonios-admin-table {
    th {
      white-space: nowrap;
    }
  }
  .testimonios-admin-clear {
    clear: both;
  }
}
