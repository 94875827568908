.detalle-donacion-dialog {
  .datos-enviados-loading {
    position: absolute;
    top: 30%;
    left: 30%;
    z-index: 999;
  }
  .sin-mensaje-exito {
    display: none;
  }
  .titulo-agregar-gasto {
    margin-bottom: 20px;
  }
  .welcome-text {
    margin-bottom: 10px;
  }
  .quitar-img-btn {
    display: inline;
  }
  .video-id-input {
    margin-top: 10px;
  }
  .imagen-testimonio-preview {
    max-width: 146px;
    max-height: 146px;
    border-radius: 8px;
  }
  .pdf-preview {
    height: 100px;
    width: 200px;
    background-color: #fb3e31;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  @media (max-width: 575px) {
    .datos-enviados-loading {
      top: 30%;
      left: 15%;
    }
  }
}
