.descripcion-seccion {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 0;
  text-align: left;
  padding-left: 10%;
}
.apoya-alternativas-monte-popup{
  display:none;
}
.descripcion-seccion-lema {
  color: rgb(46, 86, 146);
  font-size: 25px;
  text-align: right;
  padding-right: 30%;
  font-weight: 600;
}
#seccion-apoyos-especie,
#seccion-voluntariado {
  text-align: left;
  padding-left: 10%;
  font-size: 35px;
  margin-bottom: 10px;
  color: #303030;
  font-weight: 600;
}
.intro-stats {
  margin-top: 35px;
  li {
    width: 250px;
    strong.cic-counter,
    .counter-money-sign {
      font-size: 55px;
      line-height: 55px;
      color: white;
    }
    strong.cic-counter {
      text-align: right;
    }
    .stat-label-left {
      color: white;
      text-align: right;
    }
  }
}
@media (max-width: 940px) {
  #seccion-apoyos-especie,
  #seccion-voluntariado {
    padding-left: 10%;
  }
  .descripcion-seccion-lema {
    padding-right: 10%;
  }
}
@media (max-width: 535px) {
  .descripcion-seccion-lema {
    padding-right: 5%;
  }
}
.cic-content-container {
  .mensaje-principal-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: hidden;
    background-image: url("https://apoyacic.s3.amazonaws.com/imagenes/banners-06.png");
    background-size: cover;
    @media (max-width: 870px) {
      background-position: 85%;
    }
    @media (max-width: 625px) {
      background-position: -74%;
    }
    @media (max-width: 420px) {
      background-position: -65%;
    }
    .mensaje-principal {
      padding-right: 20%;
      .beneficiados-large{
        margin-right: 0;
        padding-right: 0;
        border: none;
        display: flex;
        flex-direction: column;
      }
      @media (max-width: 992px) {
        padding-right: 3%;
        .beneficiados-large, .monto-cubrir-large{
          display: none;
        }
      }
      .beneficiados-mobile, .monto-cubrir-mobile{
        display: none;
      }
      padding-top: 70px;
      padding-bottom: 50px;
      width: 100%;
      margin: auto;
      .ciudadano-msg {
        text-align: right;
      }
      .subtitulo-principal {
        font-size: 58px;
        @media (max-width: 410px) {
          font-size: 50px;
        }
        font-weight: 700;
        line-height: 1;
        margin-bottom: 10px;
        color: white;
      }
      .a-ciudadano {
        font-size: 58px;
        @media (max-width: 410px) {
          font-size: 50px;
        }
        line-height: normal;
        color: white;
        font-weight: 300;
      }
      .subtitulo-principal-b {
        font-weight: 700;
      }
      .titulo-principal-azul {
        color: rgb(23, 148, 199);
        line-height: 1;
        font-size: 65px;
      }
      .titulo-principal-negro-b {
        color: black;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
      }
      .titulo-principal-negro {
        color: black;
      }
      .subtitulo-principal-msg {
        text-align: left;
        margin-bottom: 10px;
        padding-top: 20px;
        line-height: 1.3;
        font-size: 28px;
        color: white;
        .thin-mensaje {
          font-weight: 300;
          color: white;
          font-size: 28px;
          display: inline;
        }
        .enfasis-mensaje {
          font-weight: 700;
          color: white;
          font-size: 28px;
          display: inline;
        }
      }

      @media (max-width: 992px) {
        .beneficiados-mobile, .monto-cubrir-mobile{
          display: flex;
        }
        .beneficiados-mobile{
          margin-bottom: 0;
          padding-bottom: 0;
        }
        padding-top: 50px;
        .subtitulo-principal {
          text-align: center;
        }
        .a-ciudadano {
          text-align: center;
        }
        .subtitulo-principal-msg {
          text-align: center;
          padding-top: 0;
        }
        .intro-stats {
          background: none;
          margin-top: 0px;
          padding: 0px;
          padding-top: 10px;
          .necesidades-abiertas-bloque{
            margin-bottom: 0;
            padding-bottom: 0;
          }
          .monto-cubierto-cantidad{
            justify-content: center;
          }
          li {
            border: none;
          }
          li:first-child {
            border: none;
          }
          strong.cic-counter {
            text-align: center;
          }
          .stat-label-left {
            text-align: center;
          }
        }
        .botones-wrapper{
          flex-direction: row;
          margin: auto;
          flex-wrap: wrap;
          .botones-apoyar{
            margin-bottom: 0;
            padding-bottom: 0;
            margin-right: 15px;
            margin-left: 15px;
          }
        }
      }
      .row2-mensaje {
        margin-top: 0;
      }
      .intro-stats {
        margin-top: 0px;
        padding: 0px;
        .beneficiados-count {
          text-align: left;
        }
        .beneficiados-label {
          text-align: left;
          @media (max-width: 992px) {
            text-align: center;
          }
        }
        .monto-cubrir-cantidad{
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          @media (max-width: 992px) {
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
        }
        .monto-a-cubrir-label {
          color: white;
          text-align: right;
          @media (max-width: 992px) {
            text-align: center;
          }
        }
        li {
          width: 100%;
          @media (max-width: 992px) {
            &.botones-apoyar{
              width: 200px;
            }
            &.botones-necesidad{
              width: 200px;
            }
          }
        }
        li:first-child {
          border-right: 2px solid #e0e0e0;
          @media (max-width: 992px) {
            border-right: none;
          }
        }
      }
      .intro-stats.botones-wrapper {
        justify-content: center;
        li {
          border-right: none;
        }
        .botones-apoyar {
          display: flex;
          justify-content: flex-end;
          border-right: none;
        }
        .btn-seccion-especie {
          display: none;
          background-color: orange;
        }
        .btn-seccion-voluntariado {
          display: none;
          background-color: orange;
        }
        .btn-seccion-especie-offset,
        .btn-seccion-voluntariado-offset {
          white-space: nowrap;
          margin-top: 10px;
          color: white;
          display: inline-block;
          padding: 5px;
          width: 200px;
          text-align: center;
          font-size: 20px;
          border-radius: 4px;
          background-color: orange;
          font-weight: 700;
        }
        .btn-seccion-voluntariado-offset {
          display: inline-block;
        }
        @media (max-width: 1099px) {
          .btn-seccion-especie,
          .btn-seccion-voluntariado {
            white-space: nowrap;
            margin-top: 10px;
            margin-right: 10px;
            display: inline-block;
            width: 200px;
            text-align: center;
            color: white;
            padding: 5px;
            font-size: 20px;
            border-radius: 4px;
            background-color: orange;
            font-weight: 700;
          }
          .btn-seccion-voluntariado {
            display: inline-block;
          }
          .btn-seccion-especie-offset {
            display: none;
          }
          .btn-seccion-voluntariado-offset {
            display: none;
          }
        }
      }
    }
    .seccion-imagen {
      position: relative;
      img {
        min-height: 100%;
        max-height: 650px;
        max-width: none;
      }
      .shadow-imagen {
        -webkit-box-shadow: inset 51px 0px 53px 5px #fbfbfb;
        -moz-box-shadow: inset 51px 0px 53px 5px #fbfbfb;
        box-shadow: inset 51px 0px 53px 5px #fbfbfb;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    @media (max-width: 1099px) {
      .mensaje-principal {
        max-width: 100%;
        min-width: auto;
      }
      .seccion-imagen {
        display: none;
      }
    }
  }
  .carruseles-seccion {
    background: linear-gradient(
      90deg,
      rgba(230, 230, 230, 1) 0%,
      rgba(249, 249, 249, 1) 30%,
      rgba(249, 249, 249, 1) 70%,
      rgba(230, 230, 230, 1) 100%
    );
  }
/*   .hexagonos-imagen-wrapper {
    position: relative;
    .hexagonos-imagen {
      width: 100%;
      object-fit: none;
      margin: auto;
      margin-top: 20px;
    }
    i {
      display: none;
    }
  } */

  @media (max-width: 1370px) {
    .hexagonos-imagen-wrapper {
      .hexagonos-imagen {
        object-fit: contain;
        filter: none;
      }
      i {
        display: none;
      }
    }
  }
  @media (max-width: 1000px) {
    .hexagonos-imagen-wrapper {
      .hexagonos-imagen {
        object-fit: contain;
        filter: opacity(0.5);
      }
      i {
        display: block;
        position: absolute;
        left: calc(50% - 31px);
        top: calc(50% - 31px + 20px);
        font-size: 63px;
        color: black;
        filter: drop-shadow(2px 4px 6px black);
      }
    }
  }
  .comparte-principal {
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    color: black;
  }
  .social-links {
    margin-top: 18px;
    list-style: none;
    display: flex;
    padding-left: 0px;
    justify-content: center;
    a {
      display: inline-block;
      margin-right: 10px;
      padding: 10px;
      min-width: 50px;
      max-width: 50px;
      max-height: 50px;
      min-height: 50px;
      background-color: #1894c7;
      color: white;
      border-radius: 50%;
      &.facebook-compartir {
        background-color: rgb(29, 72, 135);
      }
      &.twitter-compartir {
        background-color: rgb(30, 170, 241);
      }
      &.linkedin-compartir {
        background-color: rgb(23, 138, 207);
      }
      i {
        width: 20px;
        margin: auto;
        margin-top: 6px;
        text-align: center;
        display: block;
      }
    }
  }
}
.youtube-wrapper-principal{
  background-image: url('https://apoyacic.s3.amazonaws.com/imagenes/fondo-obscuro-yt.jpg');
  background-size: cover;
  color: white;
  text-align: center;
  display: flex;
  padding-top: 35px;
  padding-bottom: 35px;
  .video-cic-apoya{
    margin: 60px;
  }
  .como-funciona-block{
    margin-right: 60px;
    margin-bottom: 60px;
    margin-left: 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    h3{
      font-size: 55px;
      margin-bottom: 30px;
    }
    .circulos-como-funciona{
      display: flex;
      justify-content: center;
      .circulo-como-funciona{
        margin-right: 20px;
        &:last-child{
          margin-right: 0;
        }
        img{
          width: 163px;
        }
      }
    }
  }
  h3{
    color: white;
  }
  .mensaje-circulo{
    margin-top: 10px;
    width: 210px;
    font-size: 20px;
  }
}
@media(max-width: 1300px){
  .youtube-wrapper-principal{
    .como-funciona-block{
      h3{
        font-size: 40px;
      }
      .circulo-como-funciona{
        img{
          width: 140px;
        }
      }
    }
    .mensaje-circulo{
      width: 160px;
      font-size: 15px;
    }
  }
}
@media(max-width: 930px){
  .youtube-wrapper-principal{
    flex-direction: column;
    background-image: url('https://apoyacic.s3.amazonaws.com/imagenes/fondo-obscuro-yt-vertical.jpg');
    align-items: center;
    .video-cic-apoya{
      width: 100%;
    }
    .como-funciona-block{
      width: 100%;
      margin-right: 0;
      h3{
        font-size: 40px;
      }
      .circulo-como-funciona{
        img{
          width: 140px;
        }
      }
    }
    .mensaje-circulo{
      width: 160px;
      font-size: 15px;
    }
  }
}
@media(max-width: 590px){
  .youtube-wrapper-principal{
    .video-cic-apoya{
      margin-bottom: 0;
    }
    .como-funciona-block{
      margin-bottom: 20px;
      h3{
        font-size: 30px;
        margin-bottom: 15px;
      }
      .circulos-como-funciona{
        flex-direction: column;
        .circulo-como-funciona{
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &:last-child{
            margin-right: 0;
          }
          img{
            width: 90px;
          }
        }
      }
    }
    .mensaje-circulo{
      width: 170px;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}
#donaciones {
  visibility: hidden;
  position: relative;
  top: -82px;
}
@media (max-width: 1099px) {
  #donaciones {
    top: 0px;
  }
}
.donaciones-y-boletin-wrapper{
  display: flex;
  max-width: 1132px;
  max-height: 387px;
  margin: auto;
  margin-top: 50px;
  height: 387px;
  font-size: 20px;
  h3{
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 40px;
  }
  .donaciones-block{
    padding: 20px;
    width: 50%;
    text-align: center;
    background-image: url("https://apoyacic.s3.amazonaws.com/imagenes/fondo_colores_paginaprincipal_1.jpg");
    background-size: cover;
    -webkit-box-shadow: inset 0px 0px 20px 20px rgb(251, 251, 251);
    -moz-box-shadow: inset 0px 0px 20px 20px rgb(251, 251, 251);
    box-shadow: inset 0px 0px 20px 20px rgb(251, 251, 251);
    background-position: center;
    .donaciones-border-block{
      border-right: 2px solid black;
      h3{
        padding-right: 40px;
        margin-bottom: 25px;
      }
      .donaciones-subtitulo{
        width: 90%;
        margin: auto;
        color: #333;
        padding-right: 40px;
      }
      .boton-donacion, .boton-donacion-2{
        margin-top: 17px;
        margin-bottom: 10px;
        background-color: #73cade;
        width: 70px;
        height: 70px;
        line-height: 70px;
        padding: 0;
        border-radius: 35px;
        font-size: 20px;
        margin-right: 20px;
      }
      .boton-donacion:hover, .boton-donacion-2:hover{
        transform: scale(1.1);
      }
      .boton-donacion-2{
        background-color: #6dadd2;
      }
      .donar-ahora-wrapper{
        display: flex;
        justify-content: center;
        flex-direction: column;
        .error-monto-mensaje{
          color: #ff764c;
          font-size: 16px;
        }
        .input-monto-donacion{
          height: 30px;
          line-height: 24px;
          width: 150px;
          &.error-monto{
            border: 1px solid #ff764c;
          }
        }
        .boton-donar-ahora{
          background-color: #6279a9;
          border-radius: 4px;
          height: 30px;
          padding: 0;
          padding-left: 5px;
          padding-right: 5px;
          font-weight: 400;
          text-align: center;
          line-height: 30px;
          font-size: 18px;
          color: #fff;
          margin-left: 10px;
          transition: 0.3s;
        }
      }
    }
  }
  .boletin-block{
    width: 50%;
    padding: 20px;
    padding-left: 60px;
    text-align: center;
    background-image: url("https://apoyacic.s3.amazonaws.com/imagenes/fondo_colores_paginaprincipal_2.jpg");
    background-size: cover;
    -webkit-box-shadow: inset 0px 0px 20px 20px rgb(251, 251, 251);
    -moz-box-shadow: inset 0px 0px 20px 20px rgb(251, 251, 251);
    box-shadow: inset 0px 0px 20px 20px rgb(251, 251, 251);
    h3{
      margin-top: 20px;
      margin-bottom: 0;
    }
    .subtitulo-subscribete{
      font-size: 25px;
      color: #333;
      margin-bottom: 20px;
    }
    .mensaje-subscribete-boletin{
      color: #333;
      width: 90%;
    }
    img{
      width: 70px;
    }
    #mc_embed_signup-2{
      margin-top: 30px;
      #mc_embed_signup_scroll-2{
        display: flex;
        justify-content: center;
        input{
          height: 30px;
          line-height: 24px;
        }
        button{
          background-color: #6279a9;
          border-radius: 4px;
          height: 30px;
          padding: 0;
          padding-left: 5px;
          padding-right: 5px;
          font-weight: 400;
          text-align: center;
          line-height: 30px;
          font-size: 18px;
          color: #fff;
          margin-left: 10px;
          transition: 0.3s;
        }
      }
    }
  }
}
@media (max-width: 930px) {
  .donaciones-y-boletin-wrapper{
    flex-direction: column;
    align-items: center;
    max-width: none;
    max-height: none;
    height: auto;
    background-image: url("https://apoyacic.s3.amazonaws.com/imagenes/fondo_colores_paginaprincipal_1.jpg");
    background-position: center;
    background-size: cover;
    -webkit-box-shadow: inset 0px 0px 20px 0px rgb(251, 251, 251);
    -moz-box-shadow: inset 0px 0px 20px 0px rgb(251, 251, 251);
    box-shadow: inset 0px 0px 20px 0px rgb(251, 251, 251);
    .donaciones-block{
      width: 100%;
      height: 354px;
      display: flex;
      align-items: center;
      background-image: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      .donaciones-border-block{
        border: none;
        h3{
          padding-right: 0;
        }
        .donaciones-subtitulo{
          padding-right: 0;
          width: 80%;
        }
      }
    }
    .boletin-block{
      width: 100%;
      padding-left: 20px;
      height: 354px;
      display: flex;
      align-items: center;
      background-image: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      .mensaje-subscribete-boletin{
        width: 80%;
        margin: auto;
      }
    }
  }
}
@media (max-width: 440px) {
  .donaciones-y-boletin-wrapper{
    .donaciones-block{
      padding-left: 0;
      padding-right: 0;
      h3{
        margin-bottom: 20px;
      }
      .donaciones-border-block{
        .boton-donacion, .boton-donacion-2{
          width: 48px;
          height: 48px;
          line-height: 48px;
          border-radius: 24px;
          font-size: 15px;
          margin-right: 10px;
          margin-top: 20px;
        }
        .donar-ahora-wrapper{
          .input-monto-donacion{
            width: 150px;
          }
        }
      }
    }
    .boletin-block{
      margin-top: 20px;
      padding-left: 0;
      padding-right: 0;
      input{
        width: 150px;
      }
    }
  }
}
.categorias-container {
  display: flex;
  flex-direction: column;
  .titulo-categorias{
    margin-top: 10px;
    text-align: left;
    padding-left: 10%;
    font-size: 35px;
    margin-bottom: 10px;
    color: #303030;
    font-weight: 600;
  }
  .subtitulo-categorias{
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    padding-left: 10%;
  }
  .seccion-categorias {
    display: flex;
    flex-direction: column;
    #seccion-apoyos-especie-offset {
      visibility: hidden;
      position: relative;
      top: -82px;
    }
    #seccion-voluntariado-offset {
      visibility: hidden;
      position: relative;
      top: -82px;
    }
    .photo-box {
      height: 152px;
      h3 {
        font-size: 1em;
      }
      .photo-box-content {
        bottom: 15px;
        left: 15px;
      }
    }
    .columna-categorias {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-grow: 1;
      padding: 0;
      padding-right: 40px;
      @media (max-width: 930px) {
        padding-right: 0;
      }
      flex-wrap: wrap;
      .photo-box:before {
        background: rgb(0, 99, 255);
      }
      .photo-box:hover:before {
        opacity: 0.9;
      }
      .categoria-imagen {
        margin-left: 8px;
        margin-right: 8px;
        width: 277px;
      }
      .cantidad-necesidades {
        text-align: center;
        background-color: orange;
        color: white;
        font-weight: 700;
      }

    }
    .categorias-voluntariado {
      .photo-box:before {
        background: #333333bf;
      }
      .photo-box:hover:before {
        background: #d6daf999;
      }
    }
  }
}

@media (max-width: 767px) {
  .mensaje-principal-container {
    flex-wrap: wrap;
  }
}

@media (max-width: 535px) {
  .cic-content-container {
    .mensaje-principal-container {
      .mensaje-principal {
        padding-top: 30px;
      }
    }
  }
}
