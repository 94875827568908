.anuncio-apoya {
  position: fixed;
  background-color: #ffa500;
  color: white;
  border-radius: 17px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  z-index: 999;
  text-align: center;
  display: block;
  @media (max-width: 1099px) {
    position: relative;
  }
}
