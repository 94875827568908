.necesidad-publicada-close {
  border-left: none !important;
}
.titulo-publicar {
  display: block;
  font-size: 26px;
  color: #333;
}
.informacion-publicar {
  margin-top: 20px;
  margin-bottom: 20px;
}
.necesidad-publicada-diag-content {
  display: flex;
  flex-direction: column;
  i {
    font-size: 35px;
    color: #2a41e8;
    margin-bottom: 20px;
  }
}

.publicar-necesidad {
    padding-top: 40px;
  .cic-yt-input-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .cic-yt-video-url {
      max-width: 95%;
    }
    i {
      margin-top: 14px;
      color: green;
    }
    i.cic-red {
      color: red;
    }
  }
  @media (max-width: 575px) {
    #single-job-map-container {
      #singleListingMap {
        height: 200px;
      }
    }
  }
  .switch {
    margin-bottom: 10px;
    span.cic-switch-text {
      -webkit-touch-callout: none;
      -webkit-use: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 16px;
      line-height: 22px;
      color: #666;
    }
  }
  .sin-errores {
    display: none;
  }
  .input-with-icon {
    i {
      top: 60%;
    }
  }
  &.datos-enviados {
    display: none;
  }
  .descripcion-necesidad {
    min-height: 80px;
  }
  .requisitos-necesidad {
    min-height: 60px;
  }
  .imagen-necesidad-preview{
    max-width: 146px;
    max-height: 146px;
    border-radius: 8px;
  }
  #ajustar-imagen {
    width: 300px;
    height: 200px;
    padding-left: 15px;
    margin-bottom: 80px;
    .cr-slider {
      background-color: #adadad;
    }
  }
  .uploadButton .uploadButton-input2 {
    opacity: 0;
    position: absolute;
    overflow: hidden;
    z-index: -1;
    pointer-events: none;
  }
  .imagen-botones-container {
    display: flex;
    flex-direction: column;
  }
  .publicar-button-container {
    display: flex;
    justify-content: flex-end;
  }
}
