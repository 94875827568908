.item-testimonio-wrapper {
  position: relative;
  margin-bottom: 50px;
  width: calc(100% * (1 / 3) - 20px);
  .job-listing {
    height: 100%;
    margin: 0 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .job-listing-details {
      display: block;
      .descripcion-testimonio{
        text-align: center;
        .nombre-categoria {
          color: gray;
          text-align: center;
          font-weight: 600;
        }
      }

      .requisitos-label {
        color: gray;
        font-weight: 600;
      }
      .requisitos-testimonio {
        color: #333;
      }

      .costo-estimado {
        color: #333;
      }
      .imagen-testimonio {
        display: flex;
        min-width: 90px;
        justify-content: center;
        img{
          max-width: 146px;
          max-height: 146px;
          border-radius: 8px;
        }
      }
      .apoya-yt{
        border-radius: 8px;
      }
      @media (max-width: 450px) {
        .imagen-testimonio {
          flex-direction: column;
          align-items: center;
        }
      }
    }
    .job-listing-footer {
      padding: 0px;
      padding-bottom: 5px;
      width: 100%;
      .footer-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        overflow: hidden;
        .footer-item {
          margin-left: 10px;
          margin-top: 8px;
          margin-right: 10px;
          color: #333;
          display: flex;
          flex-direction: column;
          align-items: center;
          div:first-child{
            color: gray;
          }
          ul {
            li {
              margin-right: 0px;
            }
          }
          a.button{
            padding: 7px 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 1099px) {
  .item-testimonio-wrapper {
    position: relative;
    margin-bottom: 50px;
    width: calc(100% * (1 / 2) - 20px);
  }
}

@media (max-width: 767px) {
  .item-testimonio-wrapper {
    position: relative;
    margin-bottom: 50px;
    width: calc(100% - 20px);
  }
}

@media (max-width: 450px) {
  .item-testimonio-wrapper {
    position: relative;
    margin-bottom: 50px;
    width: calc(100% - 20px);
    .job-listing{
      margin: 0;
    }
  }
}