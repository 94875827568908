.listings-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10%;
  margin-right: 30px;
  width: 100%;
  justify-content: space-evenly;
}

/* @media (max-width: 1099px) {
  .listings-container {
    width: 85%;
  }
} */

@media (max-width: 767px) {
  .listings-container {
    width: 100%;
    padding-left: 0;
  }
}
