.agregar-gastos-testimonio-dialog {
  .datos-enviados-loading {
    position: absolute;
    top: 30%;
    left: 30%;
    z-index: 999;
  }
  .separacion-lista-agregar {
    width: 100%;
    border: 1px solid #d2d2d2;
    margin-bottom: 20px;
  }
  .sin-mensaje-exito {
    display: none;
  }
  .agregar-gasto-control {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    span.gasto-control-label {
      min-width: 70px;
      margin-right: 10px;
    }
    .gasto-cantidad-input {
      margin-bottom: 0;
    }
  }
  .titulo-agregar-gasto {
    margin-bottom: 20px;
  }
  .welcome-text {
    margin-bottom: 10px;
  }
  .quitar-img-btn {
    display: inline;
  }
  .video-id-input {
    margin-top: 10px;
  }
  .imagen-testimonio-preview {
    max-width: 146px;
    max-height: 146px;
    border-radius: 8px;
  }
  .pdf-preview {
    height: 100px;
    width: 200px;
    background-color: #fb3e31;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  .separador-crear-testimonio {
    width: 100%;
    height: 1px;
    border: 1px solid #d4d4d4;
    margin-bottom: 20px;
    border-top: none;
  }
  @media (max-width: 575px) {
    .datos-enviados-loading {
      top: 30%;
      left: 15%;
    }
  }
}
