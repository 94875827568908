.carrusel-brigadistas {
    padding-left: 8%;
    padding-right: 4%;
    .brigadistas-titulo {
        display: flex;
        justify-content: space-between;
        h3 {
            font-size: 35px;
            color: #303030;
            font-weight: 600;
        }
    }
    .slick-slide {
        height: 230px;
        .freelancer-name h4 {
            cursor: default;
        }
    }
    .freelancer {
        .freelancer-overview {
            padding: 0px;
            height: 100%;
            .freelancer-overview-inner {
                max-width: 100%;
                .freelancer-avatar {
                    width: 160px;
                    margin-top: 15px;
                    img {
                        display: flex;
                        justify-content: center;
                        padding-left: 25px;
                        padding-right: 25px;
                        cursor: default;
                    }
                }
                .freelancer-name {
                    margin-top: 10px;
                    .nombre-org {
                        padding: 5px;
                    }
                }
            }
            a {
                display: flex;
                justify-content: center;
                padding-left: 25px;
                padding-right: 25px;
                img {
                    max-width: 100%;
                }
            }
        }
    }
    .brigadistas-inscribete {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        i {
            margin-left: 3px;
            font-size: 20px;
            transform: scaleX(1.5);
        }
    }
}

.full-width-carousel-fix .default-slick-carousel.slick-initialized .slick-slide.slick-active.faltas-tu-btn:hover {
    transform: scale(1.1);
    cursor: pointer;
    img {
        cursor: pointer;
    }
}