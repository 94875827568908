#header-container {
  #header {
    .container {
      padding-right: 0px;
      @media (max-width: 520px) {
        padding-left: 5px;
      }
      .left-side {
        width: calc(100% - 262px);
        &.logged-in {
          width: calc(100% - 155px);
        }
        float: none;
        display: flex;
        align-items: center;
        #navigation {
          margin-top: 0px;
          width: 100%;
          ul#responsive {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            li {
              float: none;
              margin-left: 10px;
              margin-right: 10px;
              a {
                white-space: nowrap;
                padding: 0px !important;
              }
            }
          }
          @media (max-width: 1099px) {
            ul#responsive {
              display: none;
            }
          }
        }
      }
      .apoya-manos {
        display: flex;
        align-items: center;
        padding-left: 21px;
        padding-right: 21px;
        @media (max-width: 1099px) {
          padding-left: 16px;
          padding-right: 16px;
        }
        a {
          font-size: 22px;
          color: #333;
          img {
            width: 40px;
          }
          span {
            background-color: #2a41e8;
            display: inline-block;
            top: -14px;
            right: 33px;
            margin-left: -10px;
            font-weight: 700;
            height: 19px;
            width: 19px;
            line-height: 19px;
            text-align: center;
            color: #fff;
            font-size: 11px;
            border-radius: 50%;
            position: relative;
          }
        }
      }
    }
  }
}
.usuario-creado-close {
  border-left: none !important;
}

.sin-errores {
  display: none;
}

.cic-toolbar {
  .manos-header-widget{
    padding-left: 17px;
    padding-right: 17px;
    @media (max-width: 1099px) {
      padding-left: 14px;
      padding-right: 14px;
    }
  }
  .user-menu {
    .header-notifications-trigger {
      top: 55%;
    }
    .carrito-checkout-toolbar, .apoya-manos-logged{
      img{
        width: 40px;
      }
      top: 55%;
      font-size: 22px;
      color: #333;
      position: relative;
      transform: translate3d(0,-50%,0);
      a{
        font-size: 22px;
        color: #333;
        position: relative;
        transform: translate3d(0,-50%,0);
      }
      span{
        background-color: #2a41e8;
        display: inline-block;
        top: -14px;
        right: 33px;
        margin-left: -10px;
        font-weight: 700;
        height: 19px;
        width: 19px;
        line-height: 19px;
        text-align: center;
        color: #fff;
        font-size: 11px;
        border-radius: 50%;
        position: relative;
      }
    }
  }
  #logo {
    padding: 0px;
    margin: 0px;
    a {
      height: 100%;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        top: 0;
        height: auto;
        width: 100%;
        max-width: 200px;
        transform: none;
        margin-right: 20px;
        @media (max-width: 520px) {
          max-width: none;
        }
      }
    }
  }
  #navigation {
    .cic-sub-menu-apoya {
      .dropdown-nav {
        width: 326px;
      }
    }
    #responsive {
      a {
        div {
          display: inline;
          margin-right: 3px;
        }
      }
    }
  }
  @media (max-width: 1400px) {
    #navigation {
      margin-top: 29px;
      .cic-sub-menu-apoya,
      .cic-sub-menu {
        a::after {
          position: absolute;
          right: -20px;
          top: 15px;
        }
      }
      #responsive {
        a {
          div {
            display: block;
            margin-right: 0px;
          }
        }
        .cic-single-line-menu {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .cic-toolbar {
    .logged-in {
      #logo {
        a {
          img {
            max-width: calc(100vw - 262px);
          }
        }
      }
    }
  }
}
