.donaciones-wrapper {
  .titulo-tabla-donaciones {
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 20px;
  }
  .boton-tabla-donaciones {
    background-color: grey;
    color: white;
    padding: 3px;
    border-radius: 4px;
    margin-left: 5px;
  }
  .dataTables_length {
    label {
      display: inline;
    }
    select {
      height: 30px;
      width: 53px;
      display: inline-block;
      margin-bottom: 0px;
      padding: 0px;
    }
  }
  .dataTables_filter {
    label {
      display: inline;
    }
    input {
      display: inline-block;
      width: 208px;
    }
  }
  #donaciones-table {
    th {
      white-space: nowrap;
    }
  }
  .donaciones-clear {
    clear: both;
  }
}
