.faq-titulo {
    color: rgb(23, 148, 199);
    padding-top: 50px;
    margin-bottom: 40px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.faq-content {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 50px;
}

@media (max-width: 1099px) {
    .faq-titulo {
        width: 80%;
    }
    .faq-content {
        width: 80%;
    }
}

.faq-content::after {
    display: block;
    content: "";
    -webkit-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.23);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.accordion-header {
    padding: 15px;
    font-weight: 700;
    background-color: rgb(146, 146, 146);
}

.faq-respuesta {
    padding: 15px;
}