.transparencia-table-wrapper {
  .totales-transparencia{
    margin-top: 10px;
    .totales-transparencia-row{
      font-size: 18px;
      margin-bottom: 20px;
      color: #333;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      span.bloque-transparencia{
        background-color: #fff;
        box-shadow: 0 2px 6px rgba(0,0,0,0.08);
        border-radius: 4px;
        padding: 25px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-right: 30px;
        margin-top: 10px;
        .total-label{
          font-weight: 700;
        }
      }
      span.transparencia-operador{
        font-weight: 700;
        margin-right: 30px;
      }
    }
  }
  .titulo-tabla-testimonios {
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 20px;
  }
  .boton-tabla-testimonios {
    background-color: grey;
    color: white;
    padding: 3px;
    border-radius: 4px;
    margin-left: 5px;
  }
  .dataTables_length {
    label {
      display: inline;
    }
    select {
      height: 30px;
      width: 53px;
      display: inline-block;
      margin-bottom: 0px;
      padding: 0px;
    }
  }
  .dataTables_filter {
    label {
      display: inline;
    }
    input {
      display: inline-block;
      width: 208px;
    }
  }
  #transparencia-table {
    th {
      white-space: nowrap;
    }
  }
  .transparencia-clear {
    clear: both;
  }
}
