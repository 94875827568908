
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap');
html {
  body {
    #wrapper {
      #root {
        min-height: calc(100vh - 82px);
        position: relative;
        #header-container {
          position: fixed;
        }
        .cic-content-container {
          padding-bottom: 360px;
        }
        @media (max-width: 1099px) {
          .cic-content-container {
            padding-bottom: 400px;
          }
        }
        @media (max-width: 867px) {
          .cic-content-container {
            padding-bottom: 1000px;
          }
        }
        @media (max-width: 492px) {
          .cic-content-container {
            padding-bottom: 1200px;
          }
        }
      }
    }
    #wrapper.mm-page {
      #root {
        min-height: calc(100vh);
      }
    }
  }
  .hexagonos-fullsize{
    figure{
      overflow-x: scroll;
      img{
        object-fit: none;
        max-width: none;
      }
    }
  }
}
body{
  .mm-menu{
    .mm-listview{
      li{
        div{
          display: inline;
          margin-right: 3px;
        }
      }
    }
  }
}
#sign-in-dialog.dialog-with-tabs .mfp-close,
#small-dialog.dialog-with-tabs .mfp-close,
#small-dialog-1.dialog-with-tabs .mfp-close,
#small-dialog-2.dialog-with-tabs .mfp-close,
#small-dialog-3.dialog-with-tabs .mfp-close,
#small-dialog-4.dialog-with-tabs .mfp-close {
  color: #888;
  background-color: #f8f8f8;
  border-left: 1px solid #e0e0e0;
  border-radius: 0 4px 0 0;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

.float.whatsapp-boton{
	position:fixed;
	width:60px;
	height:60px;
	bottom:24px;
	right:106px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
  @media (max-width: 768px) {
    bottom: 15px;
    right: 25px;
  }
}

.my-float.whatsapp-icono{
	margin-top:16px;
}

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: .8;
  filter: alpha(opacity=80);
}

.mfp-img-mobile .mfp-figure{
  cursor: pointer;
}

.split-img-donar{
  img.mfp-img{
    max-height: 550px !important;
  }
}