.listings-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10%;
  margin-right: 30px;
  width: 100%;
  justify-content: space-evenly;
  .titulo-categoria {
    width: 100%;
    font-size: 23px;
    color: #333;
    line-height: 30px;
    margin-top: 15px;
  }
  .texto-categoria {
    width: 100%;
    font-size: 20px;
    color: #333;
    line-height: 30px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .descripcion-prioritarias-wrapper {
    display: flex;
    width: 100%;
    .descripcion-texto-block {
      width: 50%;
      text-align: center;
      .titulo-categoria{
        font-size: 40px;
        font-weight: 700;
      }
      .texto-categoria {
        margin-bottom: 15px;
      }
      .texto-categoria-b {
        width: 100%;
        font-size: 20px;
        color: #333;
        line-height: 30px;
        margin-bottom: 20px;
        font-weight: 600;
      }
    }
    .pasos-prioritarias-block{
      width: 50%;
      color: #333;
      font-weight: 600;
      .pasos-prioritarias-label{
        font-weight: 700;
      }
      .pasos-prioritarias{
        display: flex;
        text-align: center;
      }
    }
  }
  @media (max-width: 1200px) {
    .descripcion-prioritarias-wrapper {
      flex-direction: column;
      .descripcion-texto-block {
        width: 80%;
      }
      .pasos-prioritarias-block{
        width: 80%;
      }
    }
  }
}
@media (max-width: 1099px) {
  .listings-container {
    width: 85%;
  }
}

@media (max-width: 767px) {
  .listings-container {
    width: 100%;
    .titulo-categoria {
      width: 100%;
      margin-left: 30px;
    }
    .texto-categoria {
      margin-left: 30px;
      width: 100%;
    }
    .descripcion-prioritarias-wrapper {
      .descripcion-texto-block {
        .texto-categoria-b {
          margin-left: 30px;
          width: 100%;
        }
      }
      .pasos-prioritarias-block{
        width: 100%;
        margin-left: 30px;
      }
    }
  }
}
