.crear-testimonio-dialog{
  .datos-enviados-loading {
    position: absolute;
    top: 30%;
    left: 30%;
    z-index: 999;
  }
  .quitar-img-btn{
    display: inline;
  }
  .video-id-input{
    margin-top: 10px;
  }
  .imagen-testimonio-preview{
    max-width: 146px;
    max-height: 146px;
    border-radius: 8px;
  }
  .pdf-preview{
    height: 100px;
    width: 200px;
    background-color: #fb3e31;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  .separador-crear-testimonio{
    width: 100%;
    height: 1px;
    border: 1px solid #d4d4d4;
    margin-bottom: 20px;
    border-top: none;
  }
  @media (max-width: 575px) {
    .datos-enviados-loading {
      top: 30%;
      left: 15%;
    }
  }
}