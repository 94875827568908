.contacto {
  .job-listing-title {
    margin-right: 15px;
    font-size: 18px;
  }
  .label-contacto {
    font-weight: 600;
  }
  .job-listing-details {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
