.item-necesidad-wrapper {
  position: relative;
  margin-bottom: 50px;
  width: calc(100% * (1 / 2) - 20px);
  .apoya-button {
    position: absolute;
    z-index: 999;
    opacity: 0;
    top: 100%;
    left: 120px;
    transform: translateY(-10px);
    background-color: #2a41e8;
    color: white;
    padding: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    transition-property: transform opacity;
    transition-duration: 0.3s;
    transition-timing-function: linear;
  }
  .boton-paypal {
    position: absolute;
    z-index: 999;
    font-size: 16px;
    opacity: 0;
    top: 100%;
    line-height: normal;
    right: 20px;
    transform: translateY(-10px);
    background-color: orange;
    color: white;
    padding: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    transition-property: transform opacity;
    transition-duration: 0.3s;
    transition-timing-function: linear;
  }
  .boton-carrito {
    position: absolute;
    z-index: 999;
    font-size: 16px;
    opacity: 0;
    top: 100%;
    line-height: normal;
    right: calc(20px + 150px);
    transform: translateY(-10px);
    background-color: #F57C00;
    color: white;
    padding: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;
    transition-property: transform opacity;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    padding: 4px;
    width: 32px;
    height: 30px;
  }
  .job-listing {
    height: 100%;
    margin: 0 20px;
    position: relative;
    .job-listing-details {
      padding-bottom: 70px;
      display: block;
      .job-listing-title{
        font-weight: 700;
        text-align: center;
      }
      .requisitos-label {
        color: #333;
        font-weight: 600;
      }
      .requisitos-necesidad {
        color: #333;
      }
      .costo-estimado-label {
        color: #333;
        font-weight: 600;
      }
      .costo-estimado {
        color: #333;
      }
      .imagen-necesidad {
        display: flex;
        min-width: 90px;
        justify-content: space-between;
        img {
          max-width: 146px;
          max-height: 146px;
          border-radius: 8px;
        }
      }
      @media (max-width: 450px) {
        .imagen-necesidad {
          flex-direction: column;
          align-items: center;
        }
      }
    }
    .job-listing-footer {
      padding: 0px;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 70px;
      .footer-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        overflow: hidden;
        .footer-item {
          margin-left: 10px;
          margin-top: 8px;
          margin-right: 10px;
          ul {
            li {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
  .editar-costo-estimado {
    position: relative;
    z-index: 9999;
    background-color: orange;
  }
}
.item-necesidad-wrapper:hover {
  .apoya-button,
  .boton-paypal, .boton-carrito {
    opacity: 1;
    transform: translateY(0px);
    transition-property: transform opacity;
    transition-duration: 0.3s;
    transition-timing-function: linear;
  }
}
@media (max-width: 1099px) {
  .item-necesidad-wrapper {
    width: calc(100% * (1 / 2) - 20px);
    .apoya-button,
    .boton-paypal, .boton-carrito {
      opacity: 1;
      transform: translateY(0px);
      transition-property: transform opacity;
      transition-duration: 0.3s;
      transition-timing-function: linear;
    }
  }
}

@media (max-width: 1099px) {
  .item-necesidad-wrapper {
    width: calc(100% - 0px);
    margin-left: 30px;
    margin-right: 15px;
    .job-listing {
      margin-left: 0px;
    }
  }
}

@media (max-width: 575px) {
  .item-necesidad-wrapper {
    .job-listing {
      margin-right: 0px;
    }
    .apoya-button {
      left: 0;
    }
    .boton-paypal {
      right: 0;
    }
    .boton-carrito{
      right: 125px;
      height: 26px;
      width: 28px;
      padding: 2px;
      img{
        max-height: 100%;
      }
    }
    .apoya-button,
    .boton-paypal, .boton-carrito {
      font-size: 14px;
      padding-left: 3px;
      padding-right: 3px;
      line-height: 18px;
    }
    @media (max-width: 330px) {
      .boton-carrito{
        left: -30px;
        top: 100%;
      }
      .apoya-button{
        right: 0;
        left: auto;
      }
      .boton-paypal{
        left: 0;
        right: auto;
      }
    }
    .boton-carrito{
      padding-left: 4px;
      font-size: 17px;
      line-height: 18px;
      padding-right: 6px;
      padding-bottom: 2px;
    }
  }
}
