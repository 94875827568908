.checkout {
  overflow-x: hidden;
  .sin-errores {
    display: none;
  }
  .checkbox {
    margin-right: 20px;
  }
  .boton-enviar {
    margin-top: 20px;
  }
  .popup-tabs-container {
    display: flex;
    flex-direction: row;
    margin: auto;
    max-width: 580px;
    .welcome-text{
      margin-bottom: 10px;
      .transaction-id{
        background-color: #c0ffc0;
        color: black;
        border-radius: 8px;
        padding: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .datos-necesidad{
        background-color: #f1f1f1;
        color: black;
        border-radius: 8px;
        padding: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
