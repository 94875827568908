.ticker-apoya {
  margin: auto;
  .ticker-frame {
    .ticker-label {
      white-space: nowrap;
      display: flex;
      position: relative;
    }
    .ticker-label::after {
      content: "";
      border-width: 27px 20px 0px 0px;
      border-color: #0093c9 transparent transparent transparent;
      border-style: solid;
      height: 100%;
      display: inline-block;
      position: absolute;
      left: 100%;
      top: 0px;
    }
    background-color: #0093c9;
    color: white;
    display: flex;
    padding: 0px;
    padding-left: 2px;
    .ticker-content {
      background-color: white;
      color: black;
      padding-left: 18px;
      max-width: 100%;
      width: 100%;
      overflow-x: hidden;
      .ticker-content-inner {
        max-width: 100%;
        width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .ticker-content-inner-transitioning {
        max-width: 100%;
        width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transform: translateX(100%);
        transition-property: transform;
        transition-duration: 1s;
        transition-timing-function: linear;
      }
    }
    .ticker-ending {
      background-color: white;
      color: black;
    }
  }
}

@media (max-width: 430px) {
  .ticker-apoya {
    .ticker-frame {
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 0px;
      .ticker-content {
        padding-left: 0px;
        margin-left: 0px;
      }
      .ticker-ending {
        display: none;
      }
    }
  }
}
