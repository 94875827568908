.detalle-necesidad {
  .share-buttons {
    .share-buttons-content {
      i {
        color: white !important;
      }
    }
    @media (max-width: 768px) {
      .share-buttons-trigger {
        line-height: 40px;
      }
      .compartir-title {
        display: none;
      }
      .share-buttons-icons {
        position: static;
        opacity: 1;
        transform: translate(0);
      }
    }
  }
}

.item-necesidad-wrapper {
  .share-buttons {
    position: absolute;
    top: 100%;
    left: 20px;
    z-index: 999;
    .share-buttons-content {
      i {
        color: white !important;
      }
    }
    .share-buttons-trigger {
      display: none;
    }
    .compartir-title {
      display: none;
    }
    .share-buttons-icons {
      position: static;
      opacity: 0;
      transform: translateY(-10px);
      li {
        a {
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
}
@media (max-width: 1099px) {
  .item-necesidad-wrapper {
    .share-buttons {
      left: 0px;
      .share-buttons-icons {
        opacity: 1;
        transform: translateY(0px);
        transition-property: transform opacity;
        transition-duration: 0.3s;
        transition-timing-function: linear;
      }
    }
  }
}
@media (max-width: 767px) {
  .item-necesidad-wrapper {
    .share-buttons {
      left: 0px;
    }
  }
}
@media (max-width: 575px) {
  .item-necesidad-wrapper {
    .share-buttons {
      position: absolute;
      top: 0;
      left: -30px;
      .share-buttons-icons {
        flex-direction: column;
        li:first-child {
          a {
            border-radius: 4px 4px 0px 0px;
          }
        }
        li:last-child {
          a {
            border-radius: 0px 0px 4px 4px;
          }
        }
      }
    }
  }
}
.item-necesidad-wrapper:hover {
  .share-buttons {
    .share-buttons-icons {
      opacity: 1;
      transform: translateY(0px);
      transition-property: transform opacity;
      transition-duration: 0.3s;
      transition-timing-function: linear;
    }
  }
}
