.mis-necesidades{
    .job-listing-title{
        margin-right: 10px;
    }
    .job-listing-details{
        padding-left: 0px;
        .campo-editando{
            display: inline-block;
            width: auto;
            height: 26px;
        }
        .editar-titulo-btn{
            height: 26px;
            width: 26px;
            text-align: center;
            display: inline-block;
        }
        button{
            background-color: #2a41e9;
            color: white;
            padding: 2px;
            margin-right: 5px;
            border-radius: 4px;
        }
    }
    .mi-necesidad-label{
        color: #66676b;
        font-weight: 600;
    }
    .job-listing-footer{
        ul{
            display: flex;
            align-items: center;
            .costo-estimado-label{
                font-weight: 600;
            }
        }
        .cic-controls{
            background-color: #e4e4e4;
            padding: 5px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .status-abierto{
                color: #4caf50;
            }
            .status-cerrado{
                color: black;
            }
            .status-en-proceso{
                color: #ff5722;
            }
            button{
                background-color: #2a41e9;
                color: white;
                padding: 5px;
                border-radius: 4px;
            }
        }
    }
}