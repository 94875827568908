.registro-brigadista {
  overflow-x: hidden;
  .single-page-section {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    @media (max-width: 767px) {
      width: 95%;
      margin-left: 15px;
      padding-right: 25px;
    }
  }
  .preguntas {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13),
      0 6px 6px rgba(0, 0, 0, 0.23);
    padding: 20px;
  }
  @media (max-width: 1400px) {
    .preguntas{
      width: 80%;
    }
  }
  @media (max-width: 1000px) {
    .preguntas{
      width: 100%;
      box-shadow: none;
    }
  }
  @media (max-width: 400px) {
    .preguntas{
      padding: 0;
      .infoContacto{
        box-shadow: none;
      }
    }
  }
  .infoContacto {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13),
      0 6px 6px rgba(0, 0, 0, 0.23);
    padding: 20px;
  }
  .sin-errores {
    display: none;
  }
  .checkboxes-categorias {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13),
      0 6px 6px rgba(0, 0, 0, 0.23);
    padding: 20px;
    .radio {
      display: block;
      margin-right: 20px;
    }
  }
  .checkboxes-deseas {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.13),
      0 6px 6px rgba(0, 0, 0, 0.23);
    padding: 20px;
    cursor: pointer;
    .radio {
      display: block;
      margin-right: 20px;
    }
  }
  .boton-enviar {
    margin-top: 20px;
  }
  .popup-tabs-container {
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    .seccion-formulario {
      max-width: 75%;
      min-width: 75%;
      @media (max-width: 767px) {
        min-width: 100%;
        max-width: 100%;
        padding-left: 0;
      }
      .switch {
        margin-bottom: 10px;
        span.cic-switch-text {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          font-size: 16px;
          line-height: 22px;
          color: #666;
        }
      }
    }
    .seccion-imagen {
      position: relative;
      img {
        min-height: 100%;
        max-height: 945px;
        max-width: none;
      }
      .shadow-imagen {
        -webkit-box-shadow: inset 47px 5px 64px 38px #fbfbfb;
        -moz-box-shadow: inset 47px 5px 64px 38px #fbfbfb;
        box-shadow: inset 47px 5px 64px 38px #fbfbfb;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
