.bolsa-apoya-wrapper {
  /*   background-image: url("https://apoyacic.s3.amazonaws.com/imagenes/fondo_bolsa_apoya_seccion.png");
  background-size: cover; */
  .bolsa-apoya {
    display: flex;
    justify-content: space-evenly;
    .descripcion-bolsa-apoya {
      max-width: 1035px;
      margin-top: 40px;
      margin-left: 40px;
      .marcador-amarillo {
        background-color: #fdff94;
      }
      h1 {
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
        color: #333;
        margin-bottom: 15px;
      }
      .subtitulo-bolsa-apoya {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .simple-con-bold {
        font-weight: 600;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 27px;
        font-weight: 600;
        line-height: 30px;
        color: #333;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 23px;
        font-weight: 600;
        color: #333;
        margin-bottom: 15px;
      }
      div {
        font-size: 18px;
        color: #333;
        margin-bottom: 15px;
      }
      li {
        font-size: 18px;
        color: #333;
      }
    }
    .donaciones-block {
      padding: 20px;
      padding-left: 0;
      padding-right: 0;
      min-width: 420px;
      text-align: center;
      .donaciones-border-block {
        .boton-donacion,
        .boton-donacion-2 {
          margin-top: 17px;
          margin-bottom: 10px;
          background-color: #73cade;
          width: 90px;
          height: 90px;
          line-height: 90px;
          padding: 0;
          border-radius: 50px;
          font-size: 33px;
          margin-right: 20px;
        }
        .boton-donacion:hover,
        .boton-donacion-2:hover {
          transform: scale(1.1);
        }
        .boton-donacion-2 {
          background-color: #6dadd2;
        }
        .donar-ahora-wrapper {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .otra-cantidad-label {
            font-size: 25px;
          }
          .signo-moneda-label {
            font-size: 35px;
            height: 45px;
            line-height: 45px;
          }
          .error-monto-mensaje {
            color: #ff764c;
            font-size: 20px;
          }
          .input-monto-donacion {
            height: 43px;
            font-size: 32px;
            line-height: 33px;
            width: 200px;
            box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.12);
            &.error-monto {
              border: 1px solid #ff764c;
            }
          }
          .boton-donar-ahora {
            background-color: #6279a9;
            border-radius: 4px;
            height: 43px;
            padding: 0;
            padding-left: 5px;
            padding-right: 5px;
            font-weight: 400;
            text-align: center;
            line-height: 45px;
            font-size: 30px;
            color: #fff;
            margin-left: 10px;
            transition: 0.3s;
          }
        }
      }
    }
    .preview-post-medium-50-pesos{
      color: #333;
      margin-left: 40px;
      margin-right: 40px;
      img{
        max-width: 316px;
        max-height: 64px;
      }
      .preview-post-titulo{
        font-weight: 600;
      }
      a{
        display: block;
        color: #333;
      }
    }
    .slick-carousel-bolsa-apoya {
      width: 450px;
      height: 800px;
      margin: 40px;
      .slick-list {
        .freelancer {
          margin: 0;
          .freelancer-overview {
            padding: 0px;
            height: 100%;
            .freelancer-overview-inner {
              width: 100%;
              height: 100%;
              .freelancer-avatar {
                width: 160px;
                margin: 0;
                width: 100%;
                height: 100%;
                img {
                  display: flex;
                  justify-content: center;
                  border-radius: 0px;
                  object-fit: scale-down;
                  cursor: auto;
                }
              }
              .freelancer-name {
                margin-top: 10px;
                .nombre-org {
                  padding: 5px;
                }
              }
            }
            a {
              display: flex;
              justify-content: center;
              padding-left: 25px;
              padding-right: 25px;
              img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .como-funciona-block-mobile {
    display: none;
  }
}

@media (max-width: 1090px) {
  .bolsa-apoya-wrapper {
    .bolsa-apoya {
      .descripcion-bolsa-apoya {
        h1 {
          font-size: 50px;
          font-weight: 700;
        }
        h2 {
          font-size: 27px;
          line-height: 30px;
        }
        h3 {
          font-size: 27px;
          font-weight: 600;
        }
        div {
          font-size: 18px;
        }
        li {
          font-size: 18px;
        }
        .como-funciona-block {
          display: none;
        }
        .donaciones-block {
          min-width: 420px;
          text-align: center;
          .donaciones-border-block {
            .boton-donacion,
            .boton-donacion-2 {
              margin-top: 17px;
              margin-bottom: 10px;
              background-color: #73cade;
              width: 80px;
              height: 80px;
              line-height: 80px;
              padding: 0;
              border-radius: 50px;
              font-size: 30px;
              margin-right: 20px;
            }
            .boton-donacion:hover,
            .boton-donacion-2:hover {
              transform: scale(1.1);
            }
            .boton-donacion-2 {
              background-color: #6dadd2;
            }
            .donar-ahora-wrapper {
              display: flex;
              justify-content: center;
              flex-direction: column;
              .otra-cantidad-label {
                font-size: 25px;
              }
              .signo-moneda-label {
                font-size: 35px;
                height: 45px;
                line-height: 45px;
              }
              .error-monto-mensaje {
                color: #ff764c;
                font-size: 20px;
              }
              .input-monto-donacion {
                height: 43px;
                font-size: 32px;
                line-height: 33px;
                width: 200px;
                box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.12);
                &.error-monto {
                  border: 1px solid #ff764c;
                }
              }
              .boton-donar-ahora {
                background-color: #6279a9;
                border-radius: 4px;
                height: 43px;
                padding: 0;
                padding-left: 5px;
                padding-right: 5px;
                font-weight: 400;
                text-align: center;
                line-height: 45px;
                font-size: 30px;
                color: #fff;
                margin-left: 10px;
                transition: 0.3s;
              }
            }
          }
        }
      }
      .slick-carousel-bolsa-apoya {
        width: 350px;
        height: 622px;
        margin: 40px;
      }
    }
    .como-funciona-block-mobile {
      display: block;
      margin-left: 40px;
      margin-right: 10px;
      h2{
        margin-bottom: 20px;
      }
      .marcador-amarillo {
        background-color: #fdff94;
      }
      li {
        font-size: 18px;
        color: #333;
      }
    }
  }
}

@media (max-width: 820px) {
  .bolsa-apoya-wrapper {
    .bolsa-apoya {
      flex-direction: column;
      .slick-carousel-bolsa-apoya {
        display: none;
      }
      .preview-post-medium-50-pesos{
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 470px) {
  .bolsa-apoya-wrapper {
    .bolsa-apoya {
      .preview-post-medium-50-pesos{
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        img{
          max-width: 100%;
          max-height: none;
        }
      }
      .descripcion-bolsa-apoya {
        margin-left: 10px;
        margin-right: 10px;
        h1,
        h2 {
          text-align: center;
        }
        .donaciones-block.donaciones-block {
          padding-left: 0;
          padding-right: 0;
          min-width: 0;
          .donaciones-border-block {
            .boton-donacion,
            .boton-donacion-2 {
              margin-top: 17px;
              margin-bottom: 10px;
              margin-right: 8px;
              width: 60px;
              height: 60px;
              line-height: 60px;
              border-radius: 30px;
              font-size: 20px;
            }
            .donar-ahora-wrapper {
              .otra-cantidad-label {
                font-size: 20px;
              }
              .signo-moneda-label {
                font-size: 20px;
                height: 30px;
                line-height: 30px;
              }
              .error-monto-mensaje {
                color: #ff764c;
                font-size: 20px;
              }
              .input-monto-donacion {
                height: 30px;
                font-size: 20px;
                line-height: 20px;
                width: 130px;
              }
              .boton-donar-ahora {
                height: 30px;
                line-height: 30px;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    .como-funciona-block-mobile {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
