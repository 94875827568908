.cic-pager{
    display: flex;
    max-width: 100%;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
    .button-mobile{
        display: none;
    }
    a{
        box-shadow: none;
        background-color: #e8e8e8;
        color: #777;
        min-width: 5px;
        flex-shrink: 1;
        margin-right: 10px;
        &.current-page{
            background-color: #2a41e8;
            color: white;
        }
        @media (max-width: 767px) {
            &.btn-full{
                display: none;
            }
            &.button-mobile{
                display: inline-block;
            }
            padding: 0px;
            background: none;
            color: #777;
            box-shadow: none;
            &.current-page{
                background-color: #2a41e8;
                color: white;
            }
        }
    }
}